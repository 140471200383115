import { InnerProgramsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	animation,
	content,
	heading,
	link,
	loremIpsumParagraph,
	subtitle,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<InnerProgramsDistributorProps> = {
	schemaType: "module",
	component: "InnerProgramsDistributor",
	category: "distributors",
	displayName: "Programs Distributor",
	dataPacks: ["PROGRAMS"],

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional, isMockup: false },
				{ ...heading, isMockup: true },
				{ ...subtitle },
				{ ...content },
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "PROGRAM" }],
					key: "data",
					mandatory: true,
				},
				{
					title: "Which page should open?",
					type: "RadioGroup",
					key: "linkDirection",
					mandatory: false,
					options: [
						{
							value: "canonical",
							title: "Canonical site page",
							name: "pageOfCanonical",
						},
						{
							value: "site",
							title: "Current site page",
							name: "pageOfSite",
						},
					],
				},
				{
					title: "Show Calls for registration",
					type: "RadioGroup",
					key: "registrations",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{
					title: "Show image",
					type: "RadioGroup",
					key: "image",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{ ...link, title: "Button", hideable: true },
			],
		},

		{
			title: "config",
			fields: [{ ...animation }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "InnerProgramsDistributor",
		additional: null,
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		subtitle: loremIpsumParagraph,
		content: loremIpsumParagraph,
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [
				{
					structuredData: "PROGRAM",
					globalOperator: "AND",
					filterOperator: "OR",
				},
			],
			quantity: 6,
			fullRelations: true,
		},
		linkDirection: "site",
		registrations: true,
		image: false,
		link: {
			component: "Link",
		},
		animation: "none",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProgramsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProgramsDistributor/thumbnail@2x.png",
	},
};

export default schema;
